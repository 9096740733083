<template>
    <!-- Inicio Apartado si parámetro "gestion_baremos" está activado -->
    <seccion-datos :titulo="baremosdelacompania" :botonGuardar="false" tipo="secondary">
        <div>
            <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
                <div>
                    <button class="btn btn-light" style="border: 1px solid grey;" @click="toggleDialog()">Importar</button>
                </div>
                <Dialog v-model:visible="importarDialog" modal header="Importar baremo CSV" :style="{ width: '30rem' }">
                    <div class="card">
                        <div class="card-body">
                            <div style="display: grid;">
                                <div class="row">
                                    <i style="width: 10px;" class="fas fa-info"></i>
                                    <span>1º Generar Excel con el siguiente formato:</span>
                                </div>
                                <img src="@/assets/img/importBaremo.png" alt="">
                                <span>Columna A= Gremio</span>
                                <span>Columna B= Código del trabajo </span>
                                <span>Columna C= Descripción del trabajo </span>
                                <span>Columna D= Tarifa compañía </span>
                                <span>Columna E= Tarifa operario </span>
                                <div class="row" style="flex-wrap: nowrap;">
                                    <i style="width: 13px;" class="fas fa-info"></i>
                                    <span>2º Guardar el fichero como "Tipo de archivo"->"CSV delimitado por puntos y comas"</span>
                                </div>
                                <div class="row">
                                    <i style="width: 10px;" class="fas fa-info"></i>
                                    <span>3º Subir el fichero guardado:</span>
                                </div>
                                <FileUpload mode="basic" :maxFileSize="20000000" @select="subirarchivo($event)" chooseLabel="Elegir Archivo" />
                                <div class="row">
                                    <i style="width: 10px;" class="fas fa-info"></i>
                                    <span>4º Seleccionar el año del baremo</span>
                                </div>
                                <select class="form-control" style="width:85px;" v-model="anyoselec" id="anyos">
                                    <option v-for="anyo in anyos" :key="anyo" :value="anyo">{{ anyo }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div style="text-align: end;">
                                <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="importar">Importar</button>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <div class="row" style="align-items: end; gap: 15px;">
                    <input type="text" class="form-control" v-model="nombreNuevoBaremo" style="width: 300px;">
                    <button class="btn btn-primary" @click="anadirBaremo">Añadir baremo</button>
                </div>
            </div>
            <DataTable :value="baremos" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]" tableStyle="min-width: 50rem">
                <Column field="nombre" header="Nombre" style="width: 30%"></Column>
                <Column header="Fecha inicio" style="width: 25%">
                    <template #body="{ data }">
                        <input class="form-control" v-model="data.fecha_inicio" @input="actualizarFecha(data)" type="date">
                    </template> 
                </Column>
                <Column  header="Fecha fin" style="width: 25%">
                    <template #body="{ data }">
                        <input class="form-control" v-model="data.fecha_fin" @input="actualizarFecha(data)" type="date">
                    </template> 
                </Column>
                <Column header="" style="width: 10%">
                    <template #body="{ data }">
                        <button @click="abrirTrabajos(data)" class="btn btn-sm">Añadir Trabajos</button>
                    </template>                
                </Column>
                <Column header="" style="width: 10%">
                    <template #body="{ data }">
                        <button class="btn btn-sm" @click="eliminarBaremo(data)">Eliminar</button>
                    </template>
                </Column>
            </DataTable>
            <compania-baremos-trabajos :visible="visibleTrabajos" :baremo="baremoSeleccionado" :compania="datos" @update:visible="cerrarModal" @baremo-creado="handleBaremoCreado"/>
        </div>
        <template v-slot:footer>
            <p>{{ $t('general.baremos2') }}<router-link :to="{name: 'Baremos'}">{{ $t('general.baremos') }}</router-link></p>
        </template>
    </seccion-datos>
    <!-- Fin Apartado si parámetro "gestion_baremos" está activado -->
</template>
<script>
import CompaniaBaremosTrabajos from './CompaniaBaremosTrabajos.vue'
import { PwgsApi } from '../../../../services/PwgsApi';
import Dialog from 'primevue/dialog';
import FileUpload from 'primevue/fileupload';
export default {
  components: {
    'compania-baremos-trabajos': CompaniaBaremosTrabajos,
    Dialog,
    FileUpload,
  },
    props:['datos'],
    data(){
        return{
            archivoSubido:null,
            baremos:[],
            nombreNuevoBaremo:'',
            visibleTrabajos:false,
            baremoSeleccionado:null,
            importarDialog: false,
            anyos:[],
            anyoselec:'',
        }
    },
    methods:{
        async importar(){
            console.log('impr', this.archivoSubido);
            if(!this.archivoSubido){
                alert('Selecciona un archivo para importar.');
                return;
            }
            const formData = new FormData();
            formData.append('year', this.anyoselec);
            formData.append('archivo_csv_importar', this.archivoSubido);
            const api = new PwgsApi();
            const resp = await api.post('baremos-pwgs/'+this.datos.idcompañia+'/importar-baremo',formData);
            if(resp == 'OK'){
                this.$toast.add({ severity: 'success', summary: 'Realizado', detail: 'Baremo importado correctamente.', life: 2000 });
            }else{
                this.$toast.add({severity:'error', summary: 'Error', detail: 'Error al importar.', life: 5000});
            }
            this.obtenerBaremos();
            this.importarDialog = false;
        },
        subirarchivo(event) {      
            console.log('subida',event);
            this.archivoSubido = event.files[0];
            console.log('subida2',event.files[0]);
        },
        toggleDialog(){
            this.importarDialog = true;
        },
        impr(datos){
            console.log('dts',datos);
        },
        async actualizarFecha(datosbaremo){
            const api = new PwgsApi();
            await api.put('baremos-pwgs/'+this.datos.idcompañia, 
            {id_baremo:datosbaremo.id_baremo, fecha_inicio: datosbaremo.fecha_inicio, fecha_fin: datosbaremo.fecha_fin});
        },
        cerrarModal(){
            this.visibleTrabajos = false;
            this.baremoSeleccionado = null;
        },
        abrirTrabajos(data){
            this.baremoSeleccionado = data;
            this.visibleTrabajos = true;
        },
        async obtenerBaremos(){
            const api = new PwgsApi();
            const response = await api.get('baremos-pwgs/'+this.datos.idcompañia);
            this.baremos = response.datos;
            if(!Array.isArray(this.baremos)){
                this.baremos = [this.baremos];
            }
            console.log('baremos', this.baremos);
        },
        async anadirBaremo(){
            if(!this.nombreNuevoBaremo){
                alert('Debes introducir un nombre para el baremo.');
                return;
            }
            const api = new PwgsApi();
            await api.post('baremos-pwgs/'+this.datos.idcompañia, {nombre: this.nombreNuevoBaremo});
            this.nombreNuevoBaremo = '';
            this.obtenerBaremos();
        },
        async eliminarBaremo(datosbaremo){
            const api = new PwgsApi();
            await api.deletebody('baremos-pwgs/'+this.datos.idcompañia,{id_baremo:datosbaremo.id_baremo});
            this.obtenerBaremos();
        },
        getanyos(){
            const anyos = [];
            const hoy = new Date();
            for(let i=-1; i<3 ; i++){
                anyos.push(hoy.getFullYear() - i);
            }
            this.anyos = anyos;
            this.anyoselec = anyos[1];
            console.log('anos',this.anyos, this.anyoselec);
        }
    },
    mounted() {
        this.obtenerBaremos();
        this.getanyos();
     },
     watch:{
        importarDialog(value){
            if(!value){
                this.archivoSubido = null;
            }
        }
     }
}
</script>
